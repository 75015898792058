import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer} from 'rxjs';
import { BrandInformation } from './Interface/BrandInformation';


const httpOptions: { headers; } = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  constructor(private http: HttpClient) { }

  public getBrandingInformation(source: string, url: string): Observable<BrandInformation> {
    return Observable.create((observer: Observer<BrandInformation>) => {
      this.GetBrandingInformation(source, url)
        .subscribe(d => {
          const brandInfo = this.MapResponseToBrandInfo(d);
          observer.next(brandInfo);
          observer.complete();
        });
    });
  }

  private GetBrandingInformation(source: string, url: string): Observable<BrandInformation> {
    const brandUrl = url + source;
    return this.http.get<any>(brandUrl, httpOptions);
  }

  private MapResponseToBrandInfo(d: any): BrandInformation {
    return {
      CarrierLogo: d.CarrierLogo,
      CarrierLogoMobile: d.CarrierLogoMobile,
      CssLocation: d.CSSLocation,
      GtmCode: d.GtmCode,
      Source: d.Source,
      PhoneNumber: d.PhoneNumber,
      PrivacyPolicyUrl: d.BrandUrls.PrivacyPolicyUrl,
      TermsOfUseUrl: d.BrandUrls.TermsOfUseUrl,
      ProgramFactsUrl: d.BrandUrls.ProgramFactsUrl
    };
  }
}

