import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Config } from './Interface/Config';
import { config } from '../environments/environment';

@Injectable()
export class ConfigService {
  getConfig(): Observable<Config> {
    return of(config);
  }
}