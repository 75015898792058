import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';

import { AppComponent, ImagePreLoadDirective } from './app.component';
import { RouterModule } from '@angular/router';
import { HandshakeService } from './app.service';
import { BrandingService } from './branding.service';
import { ConfigService } from './config.service';

@NgModule({
  declarations: [
    AppComponent,
    ImagePreLoadDirective
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([]),
    HttpClientModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    HandshakeService,
    BrandingService,
    ConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
