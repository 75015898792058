<div id="loader">
    <picture>
        <source media="(max-width:768px)" srcset="{{mobileLogoURL}}">
        <img src={{url}} default={{defaultUrl}} class="img" /> <!-- How do we set dynamically based on current window size? Can CSS files also access variables like this? What if we have no mobile image? -->
      </picture> 
    
    <p class="partnerText"></p>
    <p class="partnerSubtitleText"></p>
    <ul>
        <li class="loadingDot"></li>
        <li class="loadingDot"></li>
        <li class="loadingDot"></li>
    </ul>
</div>
