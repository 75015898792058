export const environment = {
  production: true
};

export const config = {
  'handshakeUrl': 'https://pozb4ibnua.execute-api.us-east-1.amazonaws.com/prod/handshake',
  'carrierLogoUrl': 'https://d1iwceisv34gs6.cloudfront.net/static/source/',
  'defaultSource': 'default',
  'defaultProgressiveSource':'583',
  'brandInfoUrl': 'https://pozb4ibnua.execute-api.us-east-1.amazonaws.com/prod/source/',
  'defaultZip': '00000',
  'defaultCampaign': 'default',
  'progressiveSourcePageTitle':'Progressive Life by eFinancial',
  'fidelitySourcePageTitle':'Online Digital Experience'
};
