import { Component, OnInit, Directive, Input, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ConfigService } from './config.service';
import { Config } from './Interface/Config';
import { HandshakeService, HandshakeRequest } from './app.service';
import { BrandingService } from './branding.service';
import { BrandInformation } from './Interface/BrandInformation';
import { startGtmScript } from './analytics.service';

@Directive({
  selector: 'img[default]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src'
  }
})
export class ImagePreLoadDirective {
  @Input() src: string;
  @Input() default: string;
  @HostBinding('class') className;

  updateUrl() {
    this.src = this.default;
  }
  load() {
    this.className = 'image-loaded';
  }
}

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  source: string;
  zip: string;
  campaign: string;
  url: string;
  mobileLogoURL: string;
  defaultUrl: string;
  config: Config;
  request: HandshakeRequest;
  gtmUrl: string;
  brandInfo: BrandInformation;
  constructor(private route: ActivatedRoute, private titleService: Title, private configService: ConfigService,
              private handshake: HandshakeService, private brandSvc: BrandingService) { }
  ngOnInit() {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    this.source = params.get('source');
    this.zip = params.get('zip');
    this.campaign = params.get('campaign');
    this.configService.getConfig()
      .subscribe((data: Config) => {
        this.config = {
          handshakeUrl: data.handshakeUrl,
          carrierLogoUrl: data.carrierLogoUrl,
          defaultSource: data.defaultSource,
          defaultProgressiveSource: data.defaultProgressiveSource,
          defaultCampaign: data.defaultCampaign,
          defaultZip: data.defaultZip,
          brandInfoUrl: data.brandInfoUrl,
          progressiveSourcePageTitle: data.progressiveSourcePageTitle,
          fidelitySourcePageTitle: data.fidelitySourcePageTitle
        };
      },
      (err) => {throw err; },
      () => {
        this.url = this.config.carrierLogoUrl;
        this.defineDefaultUrl();
        this.determineWebpageTitle(this.source);
        this.setBranding(this.source);
        this.setProgressDotColor('#2480fe', '583');
        this.setProgressDotColor('#159fe0', 'gei');
        this.formatGeicoElements(this.source);
        this.getRedirectUrl(params);
      });
  }

  setBranding(source: string) {
    if (source == null || source.length === 0) {
      source = this.config.defaultSource;
    }
    this.getBrandInfo(source, this.config.brandInfoUrl);
  }
  getRedirectUrl(params: URLSearchParams) {
    this.mapHandShakeRequest(params);
    if (!this.request.Campaign) {
      this.request.Campaign = this.config.defaultCampaign;
    }
    if (!this.request.Zip) {
      this.request.Zip = this.config.defaultZip;
    }
    if (!this.request.Source) {
      this.request.Source = this.config.defaultSource;
    }
    this.handshake.getRedirectUrl(this.request, this.config.handshakeUrl)
      .subscribe(body => {
        this.redirectToUrl(body);
      });
  }
  mapHandShakeRequest(params: URLSearchParams) {
    this.request = {
      Source: params.get('source'),
      Campaign: params.get('campaign'),
      Zip: params.get('zip'),
      QueryParams: location.search,
      AppId: params.get('AppId')
    };
  }
  redirectToUrl(url: string) {
    window.location.href = url;
  }
  defineDefaultUrl() {
    this.defaultUrl = this.config.carrierLogoUrl + this.config.defaultSource + '.svg';
  }
  getBrandInfo(source: string, url: string) {
    this.brandSvc.getBrandingInformation(source, url)
      .subscribe((brandInfo: BrandInformation) => {
        this.brandInfo = brandInfo;
        startGtmScript(brandInfo.GtmCode);
        this.gtmUrl = 'https://www.googletagmanager.com/ns.html?id=' + brandInfo.GtmCode;
        this.loadCss(brandInfo.CssLocation);
        this.mobileLogoURL = brandInfo?.CarrierLogoMobile;
        this.url = brandInfo.CarrierLogo;
      });
  }
  loadCss(fileName: string) {
    if (fileName !== null) {
      const cssLink = document.createElement('link');
      cssLink.rel = 'stylesheet';
      cssLink.href = fileName;
      cssLink.type = 'text/css';
      document.head.appendChild(cssLink);
    }
  }
  determineWebpageTitle(source: string) {
    if (source !== null && source.includes('583')) {
      this.titleService.setTitle(this.config.progressiveSourcePageTitle);
    } else {
      this.titleService.setTitle(this.config.fidelitySourcePageTitle);
    }
  }
  setProgressDotColor(bgColor: string, sourcePattern: string) {
    if (this.source !== null && this.source.toLowerCase().startsWith(sourcePattern)) {
      for (let i = 0; i <= 2; i++) {
        const dot = document.getElementsByClassName('loadingDot')[i] as HTMLElement;
        dot.style.backgroundColor = bgColor;
      }
    }
  }
  formatGeicoElements(source: string) {
    if (source?.toLowerCase().startsWith('gei')) {
      const geicoImg = document.getElementsByClassName('img')[0] as HTMLElement;
      geicoImg.style.maxWidth = '100%';
    }
  }
}
