import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const httpOptions: {headers; } = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable()
export class HandshakeService {
  constructor(private http: HttpClient) { }
  getRedirectUrl(request: HandshakeRequest, url: string): Observable<any> {
    return this.http.post<any>(url, request, httpOptions);
  }
}

export interface HandshakeRequest {
    Source: string;
    Campaign: string;
    Zip: string;
    QueryParams: string;
    AppId: string;
}
